
/**
 * Internal dependencies.
 */
//import fetch from 'isomorphic-fetch';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//import { isEmpty } from 'lodash';
let authorzationToken;
if (typeof window !== 'undefined') {
  authorzationToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
}

const httpLink = createHttpLink({
  uri: `${process.env.WORDPRESS_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    }
  }
});

export const client = new ApolloClient({
  //link: authLink.concat(`${process.env.WORDPRESS_API_URL}/graphql`), 
  // uri: `${process.env.WORDPRESS_API_URL}/graphql`,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // headers: {
  //   "Authorization": authorzationToken,
  //   "Content-Type": "application/json; charset=utf-8"
  // }
});